import { getTypeProduct } from './product';
import { SUBTYPE_LENSES } from './catalog';

// Получение значений параметров линз
const getValueParamsEye = (offers, offerFields, offerId) => {
  if (!offerId || !offers || !offers.length || !offerFields || !offerFields.length) return null;

  const currOffer = offers.find((el) => el.id === offerId);
  if (!currOffer) return null;

  const paramsEye = {};
  offerFields.forEach((fieldName) => {
    if (currOffer.fields[fieldName]) {
      paramsEye[fieldName] = currOffer.fields[fieldName];
    }
  });

  return paramsEye;
};

// Получение параметров линз
const getParamsEye = (propertiesProduct, offers, offerFields) => {
  let paramsEye = null;

  if (offers && offers.length && offerFields && offerFields.length) {
    const osOfferId = propertiesProduct.offerId ? propertiesProduct.offerId : propertiesProduct.osOfferId;
    const odOfferId = propertiesProduct.odOfferId ? propertiesProduct.odOfferId : null;
    paramsEye = [];

    paramsEye.push({
      label : osOfferId && odOfferId && osOfferId !== odOfferId ? 'Левый глаз' : '',
      value : getValueParamsEye(offers, offerFields, osOfferId)
    });

    if (osOfferId && odOfferId && osOfferId !== odOfferId) {
      paramsEye.push({
        label : 'Правый глаз',
        value : getValueParamsEye(offers, offerFields, odOfferId)
      });
    }
  }

  return paramsEye;
};

// Получаем строку с параметрами линз
export const getStringParamsEye = (propertiesProduct, offers, offerFields) => {
  const paramsEye = getParamsEye(propertiesProduct, offers, offerFields);
  if (!paramsEye) return null;

  let result = '';
  paramsEye.forEach((item) => {
    if (item && item.value) {
      let str = item.label;

      Object.keys(item.value).forEach((key) => {
        const param = item.value[key];

        if (str) {
          str += ` • <span>${param.label}: ${param.value}</span>`;
        } else {
          str = `<span>${param.label}: ${param.value}</span>`;
        }
      });

      if (result) {
        result += `<br /> ${str}`;
      } else {
        result = str;
      }
    }
  });

  return result;
};

// Преобразование продукта, лежащего в корзине
const getConvertProductInBasket = (data) => {
  const title = data.title.siteTitle ? data.title.siteTitle : `${data.title.brand ? data.title.brand : ''} ${data.title.name}`;
  const typeProduct = getTypeProduct(data.types);

  return {
    basketItemId : data.basketItemId,
    productId    : data.productId,
    packId       : data.packId,
    offerId      : data.properties && data.properties.offerId ? data.properties.offerId : null,

    idAdditionalCard: data.properties && data.properties.additionalCardId ? data.properties.additionalCardId : null,
    title,

    categoryProduct : data.title.category ? data.title.category : '',
    brandProduct    : data.title.brand ? data.title.brand : '',

    code    : data.code,
    picture : {
      alt: data.picture && data.picture.alt
        ? data.picture.alt
        : '',
      default: {
        '1x' : data.picture && data.picture.default ? data.picture.default['1x'] : null,
        '2x' : data.picture && data.picture.default ? data.picture.default['2x'] : null,
        '3x' : data.picture && data.picture.default ? data.picture.default['3x'] : null
      },
      webp: {
        '1x' : data.picture && data.picture.webp ? data.picture.webp['1x'] : null,
        '2x' : data.picture && data.picture.webp ? data.picture.webp['2x'] : null,
        '3x' : data.picture && data.picture.webp ? data.picture.webp['3x'] : null
      }
    },
    types          : data.types,
    typeProduct    : typeProduct.type,
    subtypeProduct : typeProduct.subtype,
    isColor        : data.types.includes(SUBTYPE_LENSES.color_lenses.value.toUpperCase()),
    offerFields    : data.offerFields,
    properties     : data.properties,

    quantity     : data.quantity,
    points       : data.points,
    currency     : data.currency,
    defaultPrice : !data.priceWithoutDiscount ? data.price : data.priceWithoutDiscount,
    fullPrice    : null,
    discount     : null,

    couponDiscount: data.priceWithoutDiscount
      ? data.priceWithoutDiscount - data.price
      : 0

    // isAvailable : data.isAvailable,
    // type        : data.type,
    // subtype     : data.subtype,
    // links       : data.links
  };
};

// Преобразование данных корзины, полученных из апи
export const getConvertDataBasket = (basket) => {
  const result = {
    deliveryAddress : basket.deliveryAddress,
    customer        : basket.customer,
    recipient       : basket.recipient,

    isPurchaseAvailable : basket.isPurchaseAvailable,
    subscriptionTypeId  : basket.subscriptionTypeId,
    deliveryPeriod      : basket.deliveryPeriod,
    totalPrice          : basket.totalPrice,
    totalPoints         : basket.totalPoints,
    currency            : basket.currency,
    data                : basket.data,
    saleType            : basket.saleType,
    minOrderPrice       : basket.minOrderPrice,
    maxOrderItemsCount  : basket.maxOrderItemsCount,

    extra                     : basket.extra,
    totalDiscountPrice        : basket.totalDiscountPrice,
    totalPriceWithoutDiscount : basket.totalPriceWithoutDiscount,
    subscriptionTotalPacks    : basket.subscriptionTotalPacks,
    subscriptionPeriodPacks   : basket.subscriptionPerPeriodPacks,
    subscriptionTotalPrice    : basket.subscriptionTotalPrice,
    subscriptionDiscountPrice : basket.subscriptionDiscountPrice,

    promoCode : basket.promoCode,
    discounts : basket.discounts,
    items     : []
  };

  if (basket.items && basket.items.length) {
    result.items = basket.items.map((product) => getConvertProductInBasket(product));
  }

  return result;
};

// Получение количества продуктов в корзине
export const getCountOfProductsBasket = (basket) => {
  if (basket && basket.items && basket.items.length) {
    return basket.items.reduce((sum, { quantity }) => sum + quantity, 0);
  }

  return 0;
};

// Устанавливаем количество товаров в корзине
export const setCountOfProductsBasket = (elements, basket) => {
  const countProducts = getCountOfProductsBasket(basket);

  elements.forEach((el) => {
    const $el = el;
    $el.innerText = countProducts;

    if (countProducts > 0) {
      $el.classList.add('is-count');
    } else {
      $el.classList.remove('is-count');
    }
  });
};

export const getParamAddProductInBasket = (value, quantity, canEdit, collapse, additionalCardId) => {
  const result = {
    packId   : value.pack.idPack,
    quantity : canEdit && quantity ? quantity : 1
  };

  if (value.offerId) result.offerId = value.offerId;
  if (collapse) result.collapse = collapse;
  if (additionalCardId) result.additionalCardId = additionalCardId;

  return result;
};

export const getNewProductInBasket = (product, params, quantity, packId) => {
  const price = params && params.price ? params.price : {};
  const properties = {
    dimension: {
      code  : params && params.pack && params.pack.params && params.pack.params.unit ? params.pack.params.unit : null,
      value : params && params.pack && params.pack.params && params.pack.params.value ? params.pack.params.value : null
    }
  };

  if (product && params.offerId) properties.offerId = params.offerId;
  if (product && params.osOfferId) properties.osOfferId = params.osOfferId;
  if (product && params.odOfferId) properties.odOfferId = params.odOfferId;

  if (product && params.subscriptionDuration) properties.subscriptionDuration = params.subscriptionDuration;
  if (product && params.deliveryPeriod) properties.deliveryPeriod = params.deliveryPeriod;

  return {
    title   : product.title,
    picture : product.picture,
    ...price,
    properties,
    quantity,
    packId,

    brandProduct    : product.brandProduct,
    categoryProduct : product.categoryProduct,

    subscriptionDuration : params.subscriptionDuration ? params.subscriptionDuration : null,
    deliveryPeriod       : params.deliveryPeriod ? params.deliveryPeriod : null
  };
};

const getPack = (product) => {
  const valuePack = product.properties.dimension.value;
  let titlePack = '';

  if (product.properties.dimension.code === 'lenses') {
    titlePack = 'шт.';
  }
  if (product.properties.dimension.code === 'ml') {
    titlePack = 'мл.';
  }

  if (valuePack && titlePack) {
    return `${valuePack}&nbsp;${titlePack}`;
  }

  return '';
};

export const formattedListProducts = (list, modifier = 1) => {
  const resultLenses = [];
  const resultAdditional = [];

  list.forEach((group) => {
    // Выбираем первый элемент сгруппированного списка
    const firstElement = group[0];

    const resultPrice = group.reduce((sum, { defaultPrice, fullPrice, quantity }) => {
      let currPrice = defaultPrice;

      if (fullPrice) {
        currPrice = fullPrice;
      }

      return sum + (currPrice * quantity);
    }, 0);

    const element = {
      id     : firstElement && firstElement.basketItemId ? firstElement.basketItemId : null,
      price  : Math.round(resultPrice * 100) / 100,
      count  : group.reduce((sum, { quantity }) => sum + quantity, 0),
      points : {
        sum   : group.reduce((sum, { points, quantity }) => sum + (points * quantity * modifier), 0),
        // Количество баллов за 1 продукт
        value : firstElement && firstElement.points ? firstElement.points * modifier : null
      }
    };

    // Проставляем заголовок
    if (firstElement && firstElement.title) {
      const countPack = element.count && element.count > 1 ? `x&nbsp;${element.count}&nbsp;уп.` : '';
      const pack = getPack(firstElement);
      element.title = `${firstElement.title} ${pack} ${countPack}`;
    }

    if (firstElement.typeProduct === 'lenses') {
      resultLenses.push(element);
    } else {
      resultAdditional.push(element);
    }
  });

  return [...resultLenses, ...resultAdditional];
};

export const getTextPeriodLens = (period) => {
  if (period === 'monthly') {
    return '1 раз в&nbsp;месяц';
  }

  if (period === 'quarterly') {
    return '1 раз в&nbsp;3 месяца';
  }

  if (period === 'one_time') {
    return '1 раз за&nbsp;весь период подписки';
  }

  return null;
};
