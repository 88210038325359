import AbstractApiModule from '../../AbstractApiModule';
import { getAuthConfig } from '../../../utils/getAuthConfig';

const urlFactory = {
  getDetailStatisticSelection : '/v2/internal/selection/statistic/period/',
  getListIOL                  : '/v2/internal/personal/orders/mono/',
  getMainParamsIOL            : '/v2/internal/iol/check/',
  confirmAgreementIOL         : '/v2/internal/iol/confirm_agreement/',
  getSpecialistInfo           : '/v2/internal/specialist/info/',
  updatePersonalDataOIL       : '/v2/internal/iol/personal_data/',
  listRequestsOIL             : '/v2/internal/iol/requests/',
  requestOIL                  : (id) => `/v2/internal/iol/requests/${id}/`,
  dataFormRequest             : (id) => `/v2/internal/iol/requests/${id}/edit/`,
  dataDetailRequest           : (id) => `/v2/internal/iol/requests/${id}/detail/`,
  getDictionary               : '/v2/internal/iol/dictionary/',
  addFilesToApplication       : (id, type) => `/v2/internal/iol/requests/${id}/attachments/${type}/files/`,
  deleteFilesToApplication    : (id, type, idFile) => `/v2/internal/iol/requests/${id}/attachments/${type}/files/${idFile}/`,
  publishRequest              : (id) => `/v2/internal/iol/requests/${id}/publish/`,
  requestClarification        : (idRequest, idAnswer) => `/v2/internal/iol/requests/${idRequest}/answers/${idAnswer}/details/`,
  closeRequest                : (id) => `/v2/internal/iol/requests/${id}/close/`,
  copyRequest                 : (id) => `/v2/internal/iol/requests/${id}/copy/`,

  profileInfo          : '/v2/internal/specialist/profile/info/',
  profileQuestionnaire : '/v2/internal/specialist/profile/questionnaire/',
  avatarProfile        : '/v2/internal/specialist/profile/avatar/',
  workplaces           : (id) => (id ? `/v2/internal/specialist/workplaces/${id}/` : '/v2/internal/specialist/workplaces/'),
  listThemes           : '/v2/internal/specialist/themes/',
  listSpecializations  : '/v2/internal/specialist/specializations/',

  getMainPage      : '/v2/internal/specialist/batch/main-page/',
  getOrdersSamples : '/v2/internal/specialist/samples/orders/current/',

  checkUserStatusSpec: '/v1/specialist/checkUserStatus/',

  getAchievementsInfo   : '/v2/internal/specialist/batch/achievements-page/',
  getAchievementsCert   : '/v2/internal/specialist/achievements/certs/',
  getStarsCurrent       : '/v2/internal/specialist/stars/current/',
  getStarsHistory       : '/v2/internal/specialist/stars/history/',
  getStarsStats         : '/v2/internal/specialist/stars/stats/',
  getSpecialistTop      : '/v2/internal/specialist/top/',
  getSpecialistStatuses : '/v2/internal/specialist/statuses/',

  notificationsSettings: '/v2/internal/specialist/settings/'
};

export default class ProfileSpecialistModule extends AbstractApiModule {
  constructor(options) {
    super(options);
    this.apiURL = options.apiURL;
  }

  getDetailStatisticSelection(params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.getDetailStatisticSelection, params)
        .then(({ data }) => {
          if (data && data.data) {
            resolve(data.data);
          } else {
            reject(data);
          }
        })
        .catch(({ response : { data } }) => {
          reject(data);
        });
    });
  }

  getListIOL() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.listRequestsOIL).then(({ data }) => {
        resolve(data.data.requests);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  getMainParamsIOL() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getMainParamsIOL).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  confirmAgreementIOL() {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.confirmAgreementIOL).then(({ data }) => {
        if (data && data.data) {
          resolve(data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  getSpecialistInfo(request) {
    if ('memoFetch' in window && !request) {
      return new Promise((resolve, reject) => {
        window.memoFetch(`/api${urlFactory.getSpecialistInfo}`, !request).then(({ data }) => {
          resolve(data);
        }).catch(reject);
      });
    }

    return new Promise((resolve, reject) => {
      this.get(urlFactory.getSpecialistInfo).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }

  // Обновление адреса в профиле
  updatePersonalDataOIL(params) {
    return new Promise((resolve, reject) => {
      this.put(urlFactory.updatePersonalDataOIL, params).then(({ data }) => {
        if (data) {
          resolve(data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  createRequest() {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.listRequestsOIL).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  /* TODO: Удалить метод после правок бэка */
  checkPublishRequest(id) {
    return new Promise((resolve, reject) => {
      this.get(`/v2/internal/iol/requests/${id}/missing_data/`).then(({ data }) => {
        resolve(data.data.missing_data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  publishRequest(id) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.publishRequest(id)).then(({ data }) => {
        if (data) {
          resolve(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  closeRequest(id) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.closeRequest(id)).then(({ data }) => {
        if (data) {
          resolve(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  copyRequest(id) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.copyRequest(id)).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  getDictionary() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getDictionary).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  getDataFormRequest(id) {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.dataFormRequest(id)).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  getDataDetailRequest(id) {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.dataDetailRequest(id)).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  updateRequest(id, params, config) {
    return new Promise((resolve, reject) => {
      this.put(urlFactory.requestOIL(id), params, config).then(({ data }) => {
        if (data) {
          resolve(data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  deleteRequest(id) {
    return new Promise((resolve, reject) => {
      this.delete(urlFactory.requestOIL(id)).then(({ data }) => {
        if (data) {
          resolve(data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  addFilesToApplication(id, type, params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.addFilesToApplication(id, type), params).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  deleteFilesToApplication(idApplication, type, idFile) {
    return new Promise((resolve, reject) => {
      this.delete(urlFactory.deleteFilesToApplication(idApplication, type, idFile)).then(({ data }) => {
        if (data) {
          resolve(data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  requestClarification(idRequest, idAnswer, params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.requestClarification(idRequest, idAnswer), params).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  getProfileInfo() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.profileInfo).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  setProfileInfo(params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.profileInfo, params, getAuthConfig()).then(({ data }) => {
        resolve(data);
      }).catch(({ response }) => {
        if (response) {
          reject(response.data);
        }
      });
    });
  }

  setProfileQuestionnaire(params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.profileQuestionnaire, params, getAuthConfig()).then(({ data }) => {
        resolve(data);
      }).catch(({ response }) => {
        if (response) {
          reject(response.data);
        }
      });
    });
  }

  setAvatarProfile(params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.avatarProfile, params, getAuthConfig()).then(({ data }) => {
        resolve(data);
      }).catch(({ response }) => {
        if (response) {
          reject(response.data);
        }
      });
    });
  }

  deleteAvatarProfile() {
    return new Promise((resolve, reject) => {
      this.delete(urlFactory.avatarProfile, {}, getAuthConfig()).then(({ data }) => {
        resolve(data);
      }).catch(({ response }) => {
        if (response) {
          reject(response.data);
        }
      });
    });
  }

  getUserWorkplaces() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.workplaces()).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  addUserWorkplaces(params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.workplaces(), params, getAuthConfig()).then(({ data }) => {
        resolve(data);
      }).catch(({ response }) => {
        if (response) {
          reject(response.data);
        }
      });
    });
  }

  updateUserWorkplaces(id, params) {
    return new Promise((resolve, reject) => {
      this.put(urlFactory.workplaces(id), params, getAuthConfig()).then(({ data }) => {
        resolve(data);
      }).catch(({ response }) => {
        if (response) {
          reject(response.data);
        }
      });
    });
  }

  deleteUserWorkplaces(id) {
    return new Promise((resolve, reject) => {
      this.delete(urlFactory.workplaces(id), {}, getAuthConfig()).then(({ data }) => {
        resolve(data);
      }).catch(({ response }) => {
        if (response) {
          reject(response.data);
        }
      });
    });
  }

  getListThemes() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.listThemes).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  setListThemes(params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.listThemes, params, getAuthConfig()).then(({ data }) => {
        resolve(data);
      }).catch(({ response }) => {
        if (response) {
          reject(response.data);
        }
      });
    });
  }

  getListSpecializations() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.listSpecializations).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  setListSpecializations(params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.listSpecializations, params, getAuthConfig()).then(({ data }) => {
        resolve(data);
      }).catch(({ response }) => {
        if (response) {
          reject(response.data);
        }
      });
    });
  }

  getMainPage() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getMainPage).then(({ data }) => {
        if (data) {
          resolve({
            starsCurrent  : data[`/api${urlFactory.getStarsCurrent}`],
            ordersSamples : data[`/api${urlFactory.getOrdersSamples}`]
          });
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  getOrdersSamples() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getOrdersSamples).then(({ data }) => {
        resolve(data.data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  checkUserStatusSpec() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.checkUserStatusSpec).then(({ data }) => {
        resolve(data.data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  getAchievementsInfo() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getAchievementsInfo).then(({ data }) => {
        if (data) {
          resolve({
            certs         : data[`/api${urlFactory.getAchievementsCert}`],
            starsCurrent  : data[`/api${urlFactory.getStarsCurrent}`],
            starsHistory  : data[`/api${urlFactory.getStarsHistory}`],
            starsStats    : data[`/api${urlFactory.getStarsStats}`],
            specialistTop : data[`/api${urlFactory.getSpecialistTop}`]
          });
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  getAchievementsCert() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getAchievementsCert).then(({ data }) => {
        resolve(data.data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  getStarsCurrent() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getStarsCurrent).then(({ data }) => {
        resolve(data.data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  getStarsHistory() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getStarsHistory).then(({ data }) => {
        resolve(data.data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  getStarsStats() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getStarsStats).then(({ data }) => {
        resolve(data.data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  getSpecialistTop() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getSpecialistTop).then(({ data }) => {
        resolve(data.data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  getSpecialistStatuses() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getSpecialistStatuses).then(({ data }) => {
        resolve(data.data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Настройки пользователя в ЛК специалиста. Получение списка
  getNotificationsSettings() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.notificationsSettings).then(({ data }) => {
        resolve(data.data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Настройки пользователя в ЛК специалиста. Установка выбранных значений
  setNotificationsSettings(params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.notificationsSettings, params, getAuthConfig()).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }
}
